import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import * as styles from "./Project.module.scss";

interface ProjectProps
  extends React.PropsWithoutRef<React.ComponentProps<typeof Link>> {
  thumbnail: IGatsbyImageData;
  title: string;
}

function Project({ thumbnail, title, ...props }: ProjectProps): JSX.Element {
  return (
    <Link {...props} className={[props.className, styles.project].join(" ")}>
      <div className={styles.imgWrapper} data-breeze="slide-up">
        <GatsbyImage alt={title} className={styles.img} image={thumbnail} />
      </div>
      <h2 className={styles.title} data-breeze="slide-up" data-breeze-delay="1">
        {title}
      </h2>
    </Link>
  );
}

export default Project;
