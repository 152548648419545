// extracted by mini-css-extract-plugin
export var card = "Featured-module--card--hsnKO";
export var row = "Featured-module--row--qKHU9";
export var thumbnail = "Featured-module--thumbnail--Pzej4";
export var img = "Featured-module--img--4xpJb";
export var thumbnailLink = "Featured-module--thumbnail-link--nHTNs";
export var focusVisible = "Featured-module--focus-visible--X6Vct";
export var caption = "Featured-module--caption---lfvu";
export var body = "Featured-module--body--wvoVA";
export var title = "Featured-module--title--DpOZC";
export var titleLink = "Featured-module--title-link--zBxon";
export var text = "Featured-module--text--TB5Gn";
export var link = "Featured-module--link--wOEaZ";
export var linkText = "Featured-module--link-text--GVWaa";
export var linkWrapper = "Featured-module--link-wrapper--Y+Iyj";