import * as React from "react";
import Logo from "../Logo/Logo.svg";

import * as styles from "./Jumbotron.module.scss";

interface JumbotronProps {
  children: React.ReactNode;
  headline: string;
}

function Jumbotron({ headline, children }: JumbotronProps): JSX.Element {
  return (
    <div className={styles.jumbotron}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logoWrapper}>
            <Logo
              className={styles.logo}
              data-breeze="slide-up"
              data-breeze-delay="2"
              data-breeze-duration="long"
            />
          </div>
          <div className={styles.textWrapper}>
            <h1
              className={styles.headline}
              data-breeze="slide-up"
              data-breeze-delay="3"
              data-breeze-duration="long"
            >
              {headline}
            </h1>
            <div
              className={styles.intro}
              data-breeze="slide-up"
              data-breeze-delay="5"
              data-breeze-duration="long"
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jumbotron;
