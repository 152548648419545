import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import * as styles from "./Featured.module.scss";

interface FeaturedProps {
  alt: string;
  description: string;
  image: IGatsbyImageData;
  title: string;
  to: string;
}

function Featured({
  alt,
  description,
  image,
  title,
  to,
}: FeaturedProps): JSX.Element {
  return (
    <div className={styles.card} data-breeze="slide-up">
      <div className={styles.row}>
        <div className={styles.thumbnail}>
          <Link className={styles.thumbnailLink} to={to}>
            <GatsbyImage alt={alt} className={styles.img} image={image} />
          </Link>
        </div>
        <div className={styles.caption}>
          <div className={styles.body}>
            <Link
              className={styles.titleLink}
              data-breeze="slide-up"
              data-breeze-delay="1"
              to={to}
            >
              <h3 className={styles.title}>{title}</h3>
            </Link>
            <p
              className={styles.text}
              data-breeze="slide-up"
              data-breeze-delay="2"
            >
              {description}
            </p>
            <div
              className={styles.linkWrapper}
              data-breeze="slide-up"
              data-breeze-delay="3"
            >
              <Link className={styles.link} to={to}>
                <span className={styles.linkText}>more details</span>
                &nbsp;&rarr;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
