import * as React from "react";
import { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import { getImage, ImageDataLike, StaticImage } from "gatsby-plugin-image";
import sal from "sal.js";

import Layout from "../components/Layout/Layout";
import Head from "../components/Head/Head";
import Jumbotron from "../components/Jumbotron/Jumbotron";
import Featured from "../components/Featured/Featured";
import Project from "../components/Project/Project";
import Paragraph from "../components/Paragraph/Paragraph";
import Button from "../components/Button/Button";
import socialBanner from "../media/banner.png";

import * as styles from "./index.module.scss";

interface QueryTypes {
  featured: {
    frontmatter: {
      description: string;
      featured_thumbnail: ImageDataLike;
      slug: string;
      title: string;
    };
  };
  meta: {
    siteMetadata: {
      description: string;
      title: string;
    };
  };
  projects: {
    group: Array<{
      fieldValue: string;
      nodes: Array<{
        frontmatter: {
          slug: string;
          thumbnail: ImageDataLike;
          title: string;
        };
        id: string;
      }>;
    }>;
  };
}

export function getCategoryName(category: string): string {
  switch (category) {
    case "branding":
      return "branding";
    case "uiux":
      return "ui/ux design";
    case "video":
      return "video production & motion graphics";
    case "book":
      return "books";
    case "product":
      return "product design";
    case "marketing":
      return "marketing collaterals";
    case "graphics":
      return "other graphics and infographics";
    default:
      throw new Error(`Unknown category "${category}"`);
  }
}

function IndexPage({ data }: PageProps<QueryTypes>): JSX.Element {
  useEffect(() => {
    sal();
  });
  const projects = [
    data.projects.group.find((category) => category.fieldValue === "branding")!,
    data.projects.group.find((category) => category.fieldValue === "uiux")!,
    data.projects.group.find((category) => category.fieldValue === "video")!,
    data.projects.group.find((category) => category.fieldValue === "book")!,
    data.projects.group.find((category) => category.fieldValue === "product")!,
    data.projects.group.find(
      (category) => category.fieldValue === "marketing",
    )!,
    data.projects.group.find((category) => category.fieldValue === "graphics")!,
  ];
  return (
    <Layout floatingBackToTopButton hideTitle>
      <Head
        description={data.meta.siteMetadata.description}
        meta={[
          {
            name: "og:image",
            content: socialBanner,
          },
          {
            name: "og:image:alt",
            content: "Logo of Justine Severino",
          },
          {
            name: "twitter:image",
            content: socialBanner,
          },
          {
            name: "twitter:image:alt",
            content: "Logo of Justine Severino",
          },
        ]}
        title={data.meta.siteMetadata.title}
        useTitleTemplate={false}
      />
      <section className={styles.jumbotron}>
        <Jumbotron headline={data.meta.siteMetadata.description}>
          <p>
            A curious person with an eye (and heart) for the brighter side of
            things. I like to tell stories creatively through many forms of
            design. I’m inspired by people, nature and cool coffee packaging. I
            get the job done with precision, integrity and a desire to make a
            positive impact.
          </p>
          <p>Life giving you lemons? Squeeze the day with Creative Ju’s.</p>
        </Jumbotron>
      </section>
      <section
        className={[styles.paragraph, styles.anchor].join(" ")}
        id="discover"
      >
        <Paragraph title="Discover my projects">
          <p>
            Here’s a collection of some of my work throughout my career in
            design. Most of them are from freelance projects as a lot of my
            previous work contain confidential information.
          </p>
        </Paragraph>
        <div className={styles.anchorButtons}>
          {projects.map((category) => {
            return (
              <Button
                key={category.fieldValue}
                className={styles.anchorButton}
                data-breeze="slide-up"
                onClick={(): void => {
                  document.getElementById(category.fieldValue)?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                type="button"
                variant="outline-primary"
              >
                {getCategoryName(category.fieldValue)}
              </Button>
            );
          })}
        </div>
      </section>
      <section className={styles.featured}>
        <h2 className={styles.featuredTitle} data-breeze="slide-up">
          featured
        </h2>
        <Featured
          alt={data.featured.frontmatter.title}
          description={data.featured.frontmatter.description}
          image={getImage(data.featured.frontmatter.featured_thumbnail)!}
          title={data.featured.frontmatter.title}
          to={`/project/${data.featured.frontmatter.slug}`}
        />
      </section>
      <section
        className={[styles.projects, styles.anchor].join(" ")}
        id="projects"
      >
        {projects.map((category) => {
          return (
            <section
              key={category.fieldValue}
              className={[styles.projectsGroup, styles.anchor].join(" ")}
              id={category.fieldValue}
            >
              <h2 className={styles.projectListTitle} data-breeze="slide-up">
                {getCategoryName(category.fieldValue)}
              </h2>
              <div className={styles.projectList}>
                {category.nodes.map((node) => {
                  return (
                    <Project
                      key={node.id}
                      className={[styles.project, styles.anchor].join(" ")}
                      thumbnail={getImage(node.frontmatter.thumbnail)!}
                      title={node.frontmatter.title}
                      to={`/project/${node.frontmatter.slug}`}
                    />
                  );
                })}
              </div>
            </section>
          );
        })}
      </section>
      <section
        className={[styles.aboutMeWrapper, styles.anchor].join(" ")}
        id="about-me"
      >
        <h2 className={styles.aboutMeTitle} data-breeze="slide-up">
          About me
        </h2>
        <div className={styles.aboutMe}>
          <div className={styles.colLeft}>
            <div
              className={styles.profilePicture}
              data-breeze="slide-up"
              data-breeze-delay="2"
            >
              <StaticImage
                alt="A photo of Justine Severino"
                formats={["auto", "webp", "avif"]}
                placeholder="none"
                quality={95}
                src="../media/justine_severino.png"
                style={{ maxWidth: "250px" }}
                width={500}
              />
            </div>
            <Button
              className={styles.downloadButton}
              data-breeze="slide-up"
              data-breeze-delay="4"
              download="Resume-JustineSeverino.pdf"
              href="/Resume_JustineSeverino.pdf"
              variant="outline-primary"
            >
              download my full resume
            </Button>
          </div>
          <div className={styles.colRight}>
            <div className={styles.introduction}>
              <p data-breeze="slide-up" data-breeze-delay="2">
                Hi there! My name is <strong>Justine Severino</strong> but most
                people know me as Juju. I am a designer based in Biot (06),
                France, with a master’s degree in design & sustainable
                innovation. I was a senior designer in a law firm called Baker
                McKenzie, but I resigned in 2018 to pursue further studies. It
                was around this time that I started my freelance practice, as
                well.
              </p>
              <p data-breeze="slide-up" data-breeze-delay="2">
                Although I started out doing graphic design and branding work, I
                have since branched out and am now working on projects related
                to UI/UX design and video production, among other things. I am a
                naturally curious person who is always eager to learn and expand
                my knowledge.
              </p>
              <p data-breeze="slide-up" data-breeze-delay="2">
                In the work that I do, I value human connection and creating
                something that can positively impact other people every day.
              </p>
            </div>
            <div className={styles.experiencesWrapper}>
              <div className={styles.experiencesCol}>
                <div className={styles.row}>
                  <h3 className={styles.experienceTitle} data-breeze="slide-up">
                    my experience in a nutshell
                  </h3>
                  <dl
                    className={styles.experiences}
                    data-breeze="slide-up"
                    data-breeze-delay="3"
                  >
                    <dt className={styles.experienceDuration}>9 years</dt>
                    <dd className={styles.experienceName}>as a designer</dd>
                    <dt className={styles.experienceDuration}>5 years</dt>
                    <dd className={styles.experienceName}>
                      working in Baker McKenzie
                    </dd>
                    <dt className={styles.experienceDuration}>4 years</dt>
                    <dd className={styles.experienceName}>
                      freelancing for brands
                    </dd>
                    <dt className={styles.experienceDuration}>MA</dt>
                    <dd className={styles.experienceName}>
                      design & sustainable innovation
                    </dd>
                    <dt className={styles.experienceDuration}>BA</dt>
                    <dd className={styles.experienceName}>multimedia arts</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.paragraph}>
        <Paragraph title="Let’s work together">
          <p style={{ marginBottom: 0 }}>
            If you’ve got a design opportunity, feel free to drop me a message.
          </p>
          <p style={{ marginBottom: "2rem" }}>
            If our ingredients match, let’s get the juices flowing!
          </p>
          <Button to="/contact/" variant="primary">
            get in touch
          </Button>
        </Paragraph>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    featured: mdx(frontmatter: { featured: { eq: true } }) {
      frontmatter {
        description
        featured_thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        title
      }
    }
    meta: site {
      siteMetadata {
        description
        title
      }
    }
    projects: allMdx(sort: { fields: frontmatter___order }) {
      group(field: frontmatter___category) {
        fieldValue
        nodes {
          frontmatter {
            slug
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
          }
          id
        }
      }
    }
  }
`;

export default IndexPage;
