import * as React from "react";

import * as styles from "./Paragraph.module.scss";

function Paragraph({
  title,
  children,
}: {
  children?: React.ReactNode;
  title: string;
}): JSX.Element {
  return (
    <div className={styles.paragraph}>
      <h2 className={styles.title} data-breeze="slide-up">
        {title}
      </h2>
      <div data-breeze="slide-up" data-breeze-delay="1">
        {children}
      </div>
    </div>
  );
}

Paragraph.defaultProps = {
  children: undefined,
};

export default Paragraph;
