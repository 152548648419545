// extracted by mini-css-extract-plugin
export var jumbotron = "index-module--jumbotron--ywzSE";
export var paragraph = "index-module--paragraph--LM08+";
export var anchorButtons = "index-module--anchor-buttons---DeDb";
export var anchorButton = "index-module--anchor-button--IMw-k";
export var anchor = "index-module--anchor--8JfBM";
export var featured = "index-module--featured--AdoC8";
export var featuredTitle = "index-module--featured-title--LVRSS";
export var projects = "index-module--projects--uwVVw";
export var projectsGroup = "index-module--projects-group--pXxKz";
export var projectListTitle = "index-module--project-list-title--MT6Y0";
export var projectList = "index-module--project-list--043C4";
export var project = "index-module--project--bE8de";
export var aboutMeWrapper = "index-module--about-me-wrapper--PZ543";
export var aboutMeTitle = "index-module--about-me-title--qRCb7";
export var aboutMe = "index-module--about-me--g1Q2M";
export var colLeft = "index-module--col-left---P0WH";
export var colRight = "index-module--col-right--k81cD";
export var profilePicture = "index-module--profile-picture---U57w";
export var downloadButton = "index-module--download-button--5sRrn";
export var introduction = "index-module--introduction--nvoGP";
export var experiencesWrapper = "index-module--experiences-wrapper--skWtt";
export var row = "index-module--row--7ShHL";
export var experiencesCol = "index-module--experiences-col--TUlW8";
export var experiences = "index-module--experiences--JzZ3H";
export var experienceTitle = "index-module--experience-title--TbwxD";
export var experienceDuration = "index-module--experience-duration--CZVo4";
export var experienceName = "index-module--experience-name--GbTUW";